//region Imports
import {useEffect, useMemo, useRef, useState} from 'react'
import styles                                 from '../styles/Overlay.module.sass'
import cn                                     from 'classnames'
import {FaChevronLeft, FaThumbsUp, FaTimes}   from 'react-icons/fa'
//region Assets
//region Equipments
import EquipmentElevator                      from '../images/assets/elevator.svg'
import EquipmentDigicode                      from '../images/assets/digicode.svg'
import EquipmentInterphone                    from '../images/assets/interphone.svg'
import EquipmentEquipedKitchen                from '../images/assets/cuisine.svg'
import EquipmentCollectiveHeating             from '../images/assets/chaleur.svg'
import EquipmentGreenSpaces                   from '../images/assets/green_spaces.svg'
import EquipmentTerrace                       from '../images/assets/terrace.svg'
//endregion
//region Transports
import TransportLineL                         from '../images/assets/bus.png'
import TransportBus45                         from '../images/assets/ligne-l@2x.png'
//endregion
import CheckBoxIcon                           from '../images/assets/check_box light [active].svg'
//region Neighborhood
import NeighborhoodEducation                  from '../images/assets/student-hat.svg'
import NeighborhoodCommerce                   from '../images/assets/courses.svg'
import NeighborhoodHealth                     from '../images/assets/sante.svg'
import NeighborhoodSortie                     from '../images/assets/glass-cocktail.svg'
//region Transports
import NeighborhoodTransportBus               from '../images/assets/directions_bus_black_24dp.svg'
import NeighborhoodTransportTrain             from '../images/assets/directions_railway_filled_black_24dp.svg'
import NeighborhoodTransportTram              from '../images/assets/tram_black_24dp.svg'
import NeighborhoodTransportMetro             from '../images/assets/train_black_24dp.svg'
//endregion
//endregion
import EugenieIcon                            from '../images/assets/Eugenie.svg'
//endregion
import gsap                                   from 'gsap/dist/gsap'
//endregion

const Overlay = ({
	                 pageToShow,
	                 setPageToShow,
	                 setShowIframe,
	                 rooms,
	                 selectedRoom,
	                 setSelectedRoom,
	                 selectedNumberOfRooms,
	                 setSelectedNumberOfRooms,
	                 currentTab,
	                 changeCurrentTab,
                 }) => {

	//region References
	const overlayApartmentRef = useRef()
	//endregion

	//region States
	//Current tab
	const setCurrentTab                 = (tab) => {
		setPreviousTab(currentTab)
		changeCurrentTab(tab)
	}
	const [previousTab, setPreviousTab] = useState('program')
	// DEBUG - 3D ROOM
	// const [currentTab, setCurrentTab] = useState('apartments')

	const [atleastOneFilterSelected, setAtleastOneFilterSelected] = useState(false)

	//region Contact form
	const numberOfSteps = 4

	// Show contact form
	const [showContactForm, setShowContactForm] = useState(false)

	// Current contact form step
	const [currentContactFormStep, setCurrentContactFormStep] = useState(0)
	//endregion
	//endregion

	//region Handlers
	// Handle Current contact form step change
	useEffect(
		() => {

			gsap.to(
				'.steps',
				{
					xPercent: showContactForm
					          ? -100
					            / numberOfSteps
					            * currentContactFormStep
					          : 0,
				},
			)


			/*It should not be necessary but if we do not reset it, it does the animation above on '.steps' but on the '.default_overlay .content' element*/
			gsap.set(
				'.default_overlay .content',
				{
					xPercent: 0,
				},
			)
		},
		[
			showContactForm,
			currentContactFormStep,
		],
	)

	useEffect(
		() => {
			setCurrentContactFormStep(0)
		},
		[showContactForm],
	)

	// Handle current tab change
	useEffect(
		() => {
			if (currentTab
			    !== 'apartments') {
				setShowIframe(true)
			}
		},
		[
			currentTab,
			setShowIframe,
		],
	)
	//endregion

	return (
		<div
			className={styles.overlay}
		>
			{!showContactForm
			 ? <div
				 className={cn(
					 styles.default_overlay,
					 'default_overlay',
					 {
						 [styles.is_contact_us]: currentTab
						                         === 'contact-us',
						 [styles.is_apartment]:  currentTab
						                         === 'apartments'
						                         && atleastOneFilterSelected,
					 },
				 )}
			 >
				 {/*Tabs*/}
				 <div className={styles.tabs}>
					 {/*Tab - Programme*/}
					 <div
						 className={cn(
							 styles.program,
							 {
								 [styles.active]: currentTab
								                  === 'program',
							 },
						 )}

						 /*On click -> Change the current tab*/
						 onClick={() => {
							 setCurrentTab('program')
							 setPageToShow('building-page')
						 }}
					 >
						 <span>Vue d’ensemble</span>
					 </div>

					 {/*Tab - Logements*/}
					 <div
						 className={cn(
							 styles.apartments,
							 {
								 [styles.active]: currentTab
								                  === 'apartments',
							 },
						 )}

						 /*On click -> Change the current tab*/
						 onClick={() => {
							 if (currentTab
							     !== 'apartments') {
								 setCurrentTab('apartments')
								 setPageToShow('building-page')
							 }
						 }}
					 >
						 <span>Logements</span>
					 </div>

					 {/*Tab - Quartier*/}
					 <div
						 className={cn(
							 styles.neighborhood,
							 {
								 [styles.active]: currentTab
								                  === 'neighborhood',
							 },
						 )}

						 /*On click -> Change the current tab*/
						 onClick={() => {
							 setCurrentTab('neighborhood')
							 setPageToShow('neighborhood-page')
						 }}
					 >
						 <span>Quartier</span>
					 </div>
				 </div>

				 {/*region Content*/}
				 <div
					 className={cn(
						 styles.content,
						 'content',
					 )}
				 >
					 {/*Tab - Programme - Content*/}
					 <div
						 className={styles.program_content}
						 style={{
							 display: currentTab
							          === 'program'
							          ? 'flex'
							          : 'none',
						 }}
					 >
						 {/*Logement durable*/}
						 <div className={styles.durable_apartment}>
							 {/*Icon*/}
							 <FaThumbsUp className={styles.icon}/>

							 {/*Text*/}
							 <span className={styles.text}>Logement durable</span>
						 </div>

						 {/*Main apartment information*/}
						 <div className={styles.main_information}>
							 {/*Apartment name*/}
							 <span className={styles.apartment_name}>Ovation Magellan</span>
							 {/*Sub description*/}
							 <span className={styles.sub_description}>Appartements neufs à vendre à Colombes /92</span>
						 </div>

						 {/*Apartment description*/}
						 <div className={styles.apartment_description}>
							 <p>
								 Découvrez notre nouvelle résidence <strong>Ovation Magellan - Ilot 14</strong> située dans le futur quartier exceptionnel de l'Arc sportif. C'est le moment de devenir propriétaire à 14min de Paris Saint-Lazare entre Paris et La Défense !
							 </p>
							 <p>
								 Découvrez une nouvelle terre de valeurs au pied du transilien (ligne L vers Paris Saint-Lazare en 14 minutes) et du futur tramway T1. Un nouveau quartier aux nombreux atouts : des parcs, de nombreux commerces, des équipements scolaires (crèche, écoles...), des infrastructures sportives (un équipement multisports, un practice de golf), les Hautes Serres de Colombes dédiées à l'agriculture urbaine...
							 </p>
							 <p>
								 Dans ce futur nouveau quartier emblématique de l'ouest parisien, à quelques minutes du centre-ville, les résidences Ovation Magellan occupent une place de choix. Au cœur d'un grand Parc paysager, la ferme urbaine en aquaponie pour voisine, les appartements se déclinent du studio au 5 pièces duplex avec pour la plupart de magnifiques espaces extérieurs (balcons, terrasses, jardins d'hiver...).
							 </p>
						 </div>

						 {/*Caractéristiques et équipements*/}
						 <div className={styles.characteristics}>
							 {/*Title*/}
							 <h4 className={styles.title}>Caractéristiques et équipements</h4>

							 {/*List*/}
							 <div className={styles.list}>
								 {Array.from([
									             {
										             name: 'Ascenseur',
										             icon: EquipmentElevator,
									             },
									             {
										             name: 'Digicode',
										             icon: EquipmentDigicode,
									             },
									             {
										             name: 'Interphone',
										             icon: EquipmentInterphone,
									             },
									             {
										             name: 'Cuis. équipée',
										             icon: EquipmentEquipedKitchen,
									             },
									             {
										             name: 'Ch. collectif',
										             icon: EquipmentCollectiveHeating,
									             },
									             {
										             name: 'Espaces verts',
										             icon: EquipmentGreenSpaces,
									             },
									             {
										             name: 'Terrasse',
										             icon: EquipmentTerrace,
									             },
								             ])
								       .map((item, index) => (
									       <div
										       className={styles.item}
										       key={index}
									       >
										       {/*Image*/}
										       <img
											       src={item.icon}
											       alt={item.name}
											       className={styles.icon}
										       />

										       {/*Text*/}
										       <span className={styles.text}>{item.name}</span>
									       </div>
								       ))}
							 </div>

							 {/*Options description*/}
							 <div className={styles.options_description}>
								 <span>* option cuisine équipée</span>
							 </div>
						 </div>

						 {/*Transports à proximité*/}
						 <div className={styles.nearby_transport}>
							 {/*Title*/}
							 <h4 className={styles.title}>Les transports à proximité</h4>

							 {/*List*/}
							 <div className={styles.list}>
								 {Array.from([
									             {
										             name:  'Ligne L',
										             image: TransportLineL,
									             },
									             {
										             name:  'Bus 45',
										             image: TransportBus45,
									             },
								             ])
								       .map((item, index) => (
									       <div
										       className={styles.item}
										       key={index}
									       >
										       {/*Image*/}
										       <img
											       src={item.image}
											       alt={item.name}
											       className={styles.image}
										       />

										       {/*Text*/}
										       <span className={styles.text}>{item.name}</span>
									       </div>
								       ))}
							 </div>
						 </div>

						 {/*Le plus de Nexity*/}
						 <div className={styles.nexity_plus}>
							 {/*Title*/}
							 <h4 className={styles.title}>Le plus de Nexity</h4>

							 {/*List*/}
							 <div className={styles.list}>
								 {Array.from([
									             {
										             title:       'Eugénie : l\'application pour logement connecté par Nexity !',
										             description: 'Contrôlez votre logement et connectez-vous à la résidence à tout moment.\n'
										                          + 'Eugénie apporte confort, efficacité et sérénité au quotidien pour votre famille.',
										             color:       '#A594FF',
										             icon:        EugenieIcon,
									             },
									             {
										             title:       'Gamme Green, votre logement plus éco-responsable :',
										             description: 'Optimisation des déchets, économies d\'eau et d\'énergie, matériaux et fabrication à l\'empreinte écologie limitée.',
										             color:       '#7BFF9F',
										             icon:        EquipmentGreenSpaces,
									             },
								             ])
								       .map((item, index) => (
									       <div
										       className={styles.item}
										       key={index}

										       style={{backgroundColor: item.color}}
									       >
										       {/*Icon*/}
										       <img
											       src={item.icon}
											       alt={item.title}
											       className={styles.icon}
										       />

										       {/*Text*/}
										       <span className={styles.title}>{item.title}</span>

										       {/*Description*/}
										       <span className={styles.description}>{item.description}</span>
									       </div>
								       ))}
							 </div>
						 </div>

						 {/*See available apartments*/}
						 <button
							 className={styles.see_available_apartments}
							 onClick={() => setCurrentTab('apartments')}
						 >
							 Voir les appartements disponibles
						 </button>
					 </div>

					 {/*Tab - Logements - Content*/}
					 <OverlayApartmentContent
						 pageToShow={pageToShow}
						 setPageToShow={setPageToShow}
						 currentTab={currentTab}
						 setShowIframe={setShowIframe}
						 rooms={rooms}
						 selectedRoom={selectedRoom}
						 setSelectedRoom={setSelectedRoom}
						 setShowContactForm={setShowContactForm}
						 ref={overlayApartmentRef}
						 setAtleastOneFilterSelected={setAtleastOneFilterSelected}
						 selectedNumberOfRooms={selectedNumberOfRooms}
						 setSelectedNumberOfRooms={setSelectedNumberOfRooms}
					 />

					 {/*Tab - Quartier - Content*/}
					 <OverlayNeighborhoodContent currentTab={currentTab}/>

					 {/*Tab - Quartier - Content*/}
					 <OverlayContactUs
						 currentTab={currentTab}
						 goBackToPreviousTab={() => setCurrentTab(previousTab)}
					 />

					 {/*Bottom section*/}
					 <div className={styles.bottom_section}>
						 {/*Question*/}
						 <span className={styles.question}>Vous avez des questions ?</span>

						 {/*Contact us button*/}
						 <button
							 className={styles.contact_us}
							 onClick={() => {
								 setCurrentTab('contact-us')
							 }}
						 >Contactez nous
						 </button>
					 </div>
				 </div>
				 {/*endregion*/}
			 </div>
			 : <div
				 className={cn(
					 styles.contact_form,
					 {
						 [styles.is_last_step]: currentContactFormStep
						                        === numberOfSteps
						                        - 1,
					 },
				 )}
			 >
				 {/*Close button*/}
				 <span
					 className={styles.close_button}
					 onClick={() => {
						 setShowContactForm(false)
						 setPageToShow('room-page')
					 }}
				 >Retour</span>

				 {/*Steps*/}
				 <div
					 className={cn(
						 styles.steps,
						 'steps',
					 )}

					 style={{
						 width: `${100
						           * numberOfSteps}%`,
					 }}
				 >
					 {/*Step 1*/}
					 <div className={styles.step_1}>
						 {/*Title*/}
						 <h4 className={styles.title}>Pour être accompagné dans vos recherches, nous vous proposons de :</h4>

						 {/*Inputs*/}
						 <div className={styles.questions}>
							 {/*Receive offers from group Nexity*/}
							 <div className={cn(styles.receive_offers_group_nexity)}>
								 {/*Title*/}
								 <span className={styles.title}>Recevoir les offres personnalisées du groupe Nexity ?</span>

								 {/*Choices*/}
								 <div className={styles.choices}>
									 {/*No*/}
									 <label>
										 <input
											 type='radio'
											 name={'receive_offers_group_nexity'}
										 />
										 <span className={styles.text}>Non</span>
									 </label>

									 {/*Yes*/}
									 <label>
										 <input
											 type='radio'
											 name={'receive_offers_group_nexity'}
										 />
										 <span className={styles.text}>Oui</span>
									 </label>
								 </div>
							 </div>

							 {/*Receive offers from partners*/}
							 <div className={cn(styles.receive_offers_partners)}>
								 {/*Title*/}
								 <span className={styles.title}>Recevoir les offres personnalisées des partenaires du groupe Nexity ?</span>

								 {/*Choices*/}
								 <div className={styles.choices}>
									 {/*No*/}
									 <label>
										 <input
											 type='radio'
											 name={'receive_offers_partners'}
										 />
										 <span className={styles.text}>Non</span>
									 </label>

									 {/*Yes*/}
									 <label>
										 <input
											 type='radio'
											 name={'receive_offers_partners'}
										 />
										 <span className={styles.text}>Oui</span>
									 </label>
								 </div>
							 </div>
						 </div>

						 {/*Next button*/}
						 {/*TODO: Disable if all inputs are not filled*/}
						 <button
							 className={styles.next_button}
							 onClick={() => setCurrentContactFormStep(1)}
						 >Suivant
						 </button>
					 </div>

					 {/*Step 2*/}
					 <div className={styles.step_2}>
						 {/*Title*/}
						 <h4 className={styles.title}>Pour obtenir le prix et plan de ce logement et être contacté(e) par un de nos conseillers, précisez-nous :</h4>

						 {/*Main content*/}
						 <div className={styles.main_content}>
							 {/*Inputs*/}
							 <div className={styles.inputs}>
								 {/*Last name*/}
								 <div
									 className={cn(
										 styles.last_name,
										 styles.input_field,
									 )}
								 >
									 <label htmlFor={'last_name'}>Votre nom</label>
									 <input
										 type='text'
										 id={'last_name'}
										 name={'last_name'}
										 placeholder={'Doe'}
									 />
								 </div>

								 {/*First name*/}
								 <div
									 className={cn(
										 styles.first_name,
										 styles.input_field,
									 )}
								 >
									 <label htmlFor={'first_name'}>Votre prénom</label>
									 <input
										 type='text'
										 id={'first_name'}
										 name={'first_name'}
										 placeholder={'John'}
									 />
								 </div>
							 </div>
						 </div>

						 {/*Next button*/}
						 <button
							 className={styles.next_button}
							 onClick={() => setCurrentContactFormStep(2)}
						 >Suivant
						 </button>
					 </div>

					 {/*Step 3*/}
					 <div className={styles.step_3}>
						 {/*Inputs*/}
						 <div className={styles.main_content}>

							 {/*Inputs*/}
							 <div className={styles.inputs}>
								 {/*Mail*/}
								 <div
									 className={cn(
										 styles.mail,
										 styles.input_field,
									 )}
								 >
									 <label htmlFor={'mail'}>Votre email</label>
									 <input
										 type='text'
										 id={'mail'}
										 name={'mail'}
										 placeholder={'doe.john@gmail.com'}
									 />
								 </div>

								 {/*Phone number*/}
								 <div
									 className={cn(
										 styles.phone_number,
										 styles.input_field,
									 )}
								 >
									 <label htmlFor={'phone_number'}>Votre téléphone</label>
									 <input
										 type='text'
										 id={'phone_number'}
										 name={'phone_number'}
										 placeholder={'09 74 96 04 60'}
									 />
								 </div>
							 </div>

							 {/*Privacy policy*/}
							 <div
								 className={cn(
									 styles.privacy_policy,
									 styles.checkbox_field,
								 )}
							 >
								 {/*Text*/}
								 <span className={styles.text}>J’accepte que mes données soient traitées par Nexity, et/ou ses partenaires dans le cadre de ma demande et de la relation commerciale qui pourrait en découler.</span>

								 {/*Checkbox*/}
								 {/*TODO: Maybe display image*/}
								 <input
									 type={'checkbox'}
									 id={'privacy_policy'}
									 name={'privacy_policy'}
								 />
							 </div>
						 </div>

						 {/*Next button*/}
						 <button
							 className={styles.next_button}
							 onClick={() => {
								 setCurrentContactFormStep(3)

								 setPageToShow('plan-page')
							 }}
						 >Valider
						 </button>
					 </div>

					 {/*Step 4*/}
					 <div
						 className={cn(
							 styles.step_4,
							 styles.last_step,
						 )}
					 >
						 {/*Main content*/}
						 <div className={styles.main_content}>
							 {/*Thank*/}
							 <span className={styles.thank}>Merci, un de nos conseillers va vous contacter prochainement.</span>

							 {/*Apartment information*/}
							 <div className={styles.apartment_information}>
								 {/*Package number*/}
								 <span className={styles.package_number}>Lot n°2234</span>

								 {/*Type*/}
								 <span className={styles.type}>Appartement</span>

								 {/*Data*/}
								 <div className={styles.data}>
									 {/*Rooms*/}
									 <span className={styles.rooms}>4 pièces</span>

									 {/*Surface*/}
									 <span className={styles.surface}>75 m²</span>

									 {/*Stage*/}
									 <span className={styles.stage}>Étage 5</span>

									 {/*Parking*/}
									 <span className={styles.parking}>Parking</span>
								 </div>

								 {/*Price*/}
								 <div className={styles.price}>
									 {/*Value*/}
									 <span className={styles.value}>475 540 €</span>

									 {/*Tax type*/}
									 <span className={styles.tax_type}>TTC</span>
								 </div>
							 </div>

							 {/*Download the plan in PDF*/}
							 <a
								 href={'/Exemple_PDF_Nexity.pdf'}
								 target={'_blank'}
								 className={styles.download_plan_in_pdf}
							 >Télécharger le plan</a>
						 </div>

						 {/*Back button*/}
						 <span
							 className={styles.go_back_to_3d_view}
							 onClick={() => {
								 setShowContactForm(false)
								 setPageToShow('room-page')
							 }}
						 >Retour à la vue d'ensemble</span>
					 </div>
				 </div>
			 </div>}
		</div>
	)
}

const OverlayApartmentContent = ({
	                                 pageToShow,
	                                 setPageToShow,
	                                 currentTab,
	                                 setShowIframe,
	                                 rooms,
	                                 selectedRoom,
	                                 setSelectedRoom,
	                                 setShowContactForm,
	                                 setAtleastOneFilterSelected,
	                                 selectedNumberOfRooms,
	                                 setSelectedNumberOfRooms,
                                 }) => {
	//region Data
	// Rooms
	const numberOfRooms = useMemo(
		() => {
			return [
				{
					title:   'Studio',
					enabled: true,
				},
				{
					title:   '2',
					enabled: false,
				},
				{
					title:   '3',
					enabled: true,
				},
				{
					title:   '4',
					enabled: true,
				},
				{
					title:   '5',
					enabled: true,
				},
			]
		},
		[],
	)

	// Stages
	const stages = useMemo(
		() => {
			return [
				{
					title:   'RdC',
					enabled: true,
				},
				{
					title:   '1',
					enabled: true,
				},
				{
					title:   '2',
					enabled: true,
				},
				{
					title:   '3',
					enabled: true,
				},
				{
					title:   '4',
					enabled: true,
				},
				{
					title:   '5',
					enabled: true,
				},
				{
					title:   '6',
					enabled: false,
				},
				{
					title:   '7',
					enabled: true,
				},
			]
		},
		[],
	)
	//endregion

	//region States
	// Selected stage
	const [selectedStage, setSelectedStage] = useState(null)

	// At least one filter selected
	const atLeastOneFilterSelected = useMemo(
		() => {
			const atLeastOneFilterSelected = selectedNumberOfRooms
			                                 !== null
			                                 || selectedStage
			                                 !== null

			/*Change iFrame with an image to select apartments*/
			setShowIframe(!atLeastOneFilterSelected)

			return atLeastOneFilterSelected
		},
		[
			selectedNumberOfRooms,
			selectedStage,
			currentTab,
		],
	)
	//endregion

	//region Handlers
	// Handle filter selection
	useEffect(
		() => {
			/*Transition between global information panes*/
			gsap.to(
				`.apartments_global_information`,
				{
					xPercent: atLeastOneFilterSelected
					          ? 0
					          : -50,
				},
			)

			setAtleastOneFilterSelected(atLeastOneFilterSelected)
		},
		[
			atLeastOneFilterSelected,
		],
	)

	// Handle page to show change
	useEffect(
		() => {
			/*Animation between two overlay panes*/
			gsap.to(
				`.apartments_content`,
				{
					xPercent: pageToShow
					          === 'building-page'
					          ? 0
					          : -50,
				},
			)
		},
		[pageToShow],
	)
	//endregion

	return <div
		className={cn(
			styles.apartments_content,
			'apartments_content',
		)}
		style={{
			display: currentTab
			         === 'apartments'
			         ? 'flex'
			         : 'none',
		}}
	>
		{/*Building pane*/}
		<div className={styles.building_pane}>
			{/*Apartments global information*/}
			<div
				className={cn(
					styles.apartments_global_information,
					'apartments_global_information',
				)}
			>
				{/*Information displayed when there is no filter selected*/}
				<div className={styles.no_filter}>
					{/*Number of apartments*/}
					<span className={styles.number_of_apartments}>48 logements disponibles</span>

					{/*Number of rooms interval and surface interval*/}
					<span className={styles.rooms_and_surface_interval}>De 1 à 5 pièces · de 24m² à 117m²</span>

					{/*Price interval*/}
					<span className={styles.price_interval}>De 318 000€ à 477 000€</span>
				</div>

				{/*Information displayed when there is a filter selected*/}
				<div className={styles.filter}>
					{/*Subtitle*/}
					<span className={styles.subtitle}>Votre sélection :</span>

					{/*Title*/}
					<span className={styles.title}>Appartement 4 pièces</span>

					{/*Filter result description*/}
					<span className={styles.filter_result_description}>
					{/*Number of results*/}
						<span className={styles.number_of_results}>6 appartements T4 disponibles</span>

						{/*Surface and price interval*/}
						<span className={styles.surface_and_price_interval}>De 64m² à 89m² · <span className={styles.price_interval}>De 402 000€ à 477 000€</span></span>
				</span>
				</div>
			</div>

			{/*Filter available apartments*/}
			<div className={styles.filter_available_apartments}>
				{/*Title*/}
				<h4 className={styles.title}>Filtrer les logements disponibles</h4>

				{/*Type (rooms)*/}
				<div className={styles.type}>
					{/*Title*/}
					<span className={styles.title}>Nombre de pièces</span>

					{/*List*/}
					<div className={styles.list}>
						{numberOfRooms.map((item, index) => (
							<div
								key={index}
								className={cn(
									styles.item,
									{
										[styles.selected]: selectedNumberOfRooms
										                   === index,
										[styles.disabled]: !item.enabled,
									},
								)}

								//region Handlers
								// On click -> change selected room (if the clicked room is enabled)
								onClick={() => {
									if (item.enabled) {
										setSelectedNumberOfRooms(selectedNumberOfRooms
										                         === index
										                         ? null
										                         : index)
									}
								}}
								//endregion
							>
								<span className={styles.title}>{item.title}</span>
							</div>
						))}

					</div>
				</div>

				{/*Stage*/}
				<div className={styles.stage}>
					{/*Title*/}
					<span className={styles.title}>Étage</span>

					{/*List*/}
					<div className={styles.list}>
						{stages.map((item, index) => (
							<div
								key={index}
								className={cn(
									styles.item,
									{
										[styles.selected]: selectedStage
										                   === index,
										[styles.disabled]: !item.enabled,
									},
								)}

								//region Handlers
								// On click -> change selected stage (if the clicked stage is enabled)
								onClick={() => {
									if (item.enabled) {
										setSelectedStage(selectedStage
										                 === index
										                 ? null
										                 : index)
									}
								}}
								//endregion
							>
								<span className={styles.title}>{item.title}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>

		{/*Apartment pane*/}
		<div className={styles.apartment_pane}>
			{/*General apartment information*/}
			<div className={styles.general_apartment_information}>
				{/*Go back button*/}
				<div
					className={styles.go_back_button}

					/*region Handlers*/
					/*On click -> Set page to show to building page*/
					onClick={() => {
						setPageToShow('building-page')
					}}
					/*endregion*/
				>
					<FaChevronLeft className={styles.icon}/>
				</div>

				{/*Content*/}
				<div className={styles.content}>
					{/*Package number*/}
					<span className={styles.package_number}>Lot n°2234</span>

					{/*Type*/}
					<span className={styles.type}>Appartement</span>

					{/*Rooms and surface*/}
					<span className={styles.rooms_and_surface}>4 pièces · 75m²</span>

					{/*Stage and parking*/}
					<span className={styles.stage_and_parking}>Étage 2 · Parking</span>
				</div>
			</div>

			{/*Bottom content*/}
			<div className={styles.bottom_content}>
				{/*Rooms selection*/}
				<div className={styles.rooms_selection}>
					{/*Top section*/}
					<div className={styles.top_section}>
						{/*Title*/}
						<span className={styles.title}>Pièces</span>

						{/*Recenter*/}
						<span
							className={styles.recenter}
							onClick={() => {
								setSelectedRoom(rooms[0].id)
							}}
						>Recentrer</span>
					</div>

					{/*List*/}
					<div className={styles.list}>
						{/*Get rooms except first one*/}
						{rooms.slice(1)
						      .map((item, index) => (
							      <div
								      className={cn(
									      styles.item,
									      {
										      [styles.selected]: selectedRoom
										                         === item.id,
									      },
								      )}
								      key={index}

								      /*region Handlers*/
								      /*On click -> Set selected room*/
								      onClick={() => {
									      setSelectedRoom(item.id)
								      }}
								      /*endregion*/
							      >
								      {/*Title*/}
								      <span className={styles.title}>{item.name}</span>
							      </div>
						      ))}
					</div>
				</div>

				{/*See price and plan button*/}
				<button
					className={styles.see_price_and_plan}

					/*region Handlers*/
					/*On click -> Show contact form overlay*/
					onClick={() => {
						setShowContactForm(true)
					}}
					/*endregion*/
				>Voir le prix et le plan
				</button>
			</div>
		</div>
	</div>
}

const OverlayNeighborhoodContent = ({
	                                    currentTab,
                                    }) => {
	const filters  = useMemo(
		() => [
			{
				id:    styles.education,
				title: 'Éducation',
				icon:  NeighborhoodEducation,
				color: '#A594FF',
			},
			{
				id:    styles.commerce,
				title: 'Commerce',
				icon:  NeighborhoodCommerce,
				color: '#FFF385',
			},
			{
				id:    styles.sante,
				title: 'Santé',
				icon:  NeighborhoodHealth,
				color: '#7BFF9F',
			},
			{
				id:    styles.sortie,
				title: 'Sortie',
				icon:  NeighborhoodSortie,
				color: '#FFC1F6',
			},
			{
				id:    styles.transports,
				title: 'Transports',
				icon:  NeighborhoodTransportBus,
				color: '#69DFFF',
			},
		],
		[],
	)
	const networks = useMemo(
		() => [
			{
				title: 'Bus',
				icon:  NeighborhoodTransportBus,
			},
			{
				title: 'Train',
				icon:  NeighborhoodTransportTrain,
			},
			{
				title: 'Tram',
				icon:  NeighborhoodTransportTram,
			},
			{
				title: 'Métro',
				icon:  NeighborhoodTransportMetro,
			},
		],
		[],
	)

	return (
		<div
			className={styles.neighborhood_content}
			style={{
				display: currentTab
				         === 'neighborhood'
				         ? 'flex'
				         : 'none',
			}}
		>
			{/*Filters*/}
			<div className={styles.filters}>
				{filters.map((item, index) => (
					<div
						key={index}
						className={cn(
							styles.item,
							item.id,
						)}
					>
						{/*Top colored*/}
						<div
							className={styles.top_colored}
							style={{backgroundColor: item.color}}
						>
							{/*Icon*/}
							<img
								src={item.icon}
								className={styles.icon}
								alt={'Icon'}
							/>

							{/*Checkbox*/}
							<img
								src={CheckBoxIcon}
								className={styles.checkbox}
								alt={'Checkbox'}
							/>
						</div>

						{/*Title*/}
						<span className={styles.title}>{item.title}</span>
					</div>
				))}
			</div>


			{/*Networks*/}
			<div className={styles.networks}>
				{/*Title*/}
				<span className={styles.title}>Réseaux :</span>

				{/*List*/}
				<div className={styles.list}>
					{networks.map((item, index) => (
						<div
							key={index}
							className={styles.item}
						>
							{/*Icon*/}
							<img
								src={item.icon}
								className={styles.icon}
								alt={'Icon'}
							/>

							{/*Title*/}
							<span className={styles.title}>{item.title}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

const OverlayContactUs = ({
	                          currentTab,
	                          goBackToPreviousTab,
                          }) => {

	return (
		<div
			className={styles.contact_us}
			style={{
				display: currentTab
				         === 'contact-us'
				         ? 'flex'
				         : 'none',
			}}
		>
			{/*Close button*/}
			<div
				className={styles.close_button}
				onClick={goBackToPreviousTab}
			><FaTimes className={styles.icon}/></div>

			{/*Phone section*/}
			<div className={styles.phone_section}>
				{/*Title*/}
				<span className={styles.title}>Discuter avec nos conseillers</span>

				{/*Phone number*/}
				<span className={styles.phone_number}>0 800 97 50 50</span>

				{/*Description*/}
				<span className={styles.description}>(Service et appel gratuits)<br/>Nos conseillers vous répondent du lundi au vendredi de 9h à 21h, le samedi de 9h à 20h, le dimanche et jours fériés de 10h à 18h</span>
			</div>

			{/*Demande d'information*/}
			<div className={styles.demande_information}>
				{/*Title*/}
				<span className={styles.title}>Demande d'information</span>

				{/*Form*/}
				<div className={styles.form}>
					{/*Last name*/}
					<label className={styles.last_name}>
						<span className={styles.text}>Nom*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*First name*/}
					<label className={styles.first_name}>
						<span className={styles.text}>Prénom*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*Email address*/}
					<label className={styles.email_address}>
						<span className={styles.text}>Adresse mail*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*Phone number*/}
					<label className={styles.phone_number}>
						<span className={styles.text}>Téléphone*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*Message (optional)*/}
					<label className={styles.message}>
						<span className={styles.text}>Message facultatif</span>
						{/*Input*/}
						<textarea rows={3}></textarea>
					</label>

					{/*Checkbox accept data*/}
					<label className={styles.checkbox_accept_data}>
						{/*Checkbox*/}
						<input type='checkbox'/>
						{/*Text*/}
						<span className={styles.text}>J'accepte que mes données soient traitées par Nexity, et/ou ses partenaires dans le cadre de ma demande et de la relation commerciale qui pourrait en découler</span>
					</label>

					{/*Choice - Receive personalised offers from Nexity group*/}
					<div className={styles.choice_receive_personalised_offers}>
						{/*Text*/}
						<span className={styles.text}>Je souhaite recevoir les offres personnalisées du groupe Nexity *</span>

						{/*Choices*/}
						<div className={styles.choices}>
							{/*Choice - Yes*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Oui</span>
							</label>

							{/*Choice - No*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Non</span>
							</label>
						</div>
					</div>

					{/*Choice - Receive personalised offers from Nexity partners*/}
					<div className={styles.choice_receive_personalised_partners_offers}>
						{/*Text*/}
						<span className={styles.text}>Je souhaite recevoir les offres personnalisées des partenaires du groupe Nexity *</span>

						{/*Choices*/}
						<div className={styles.choices}>
							{/*Choice - Yes*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Oui</span>
							</label>

							{/*Choice - No*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Non</span>
							</label>
						</div>
					</div>

					{/*Send button*/}
					<button className={styles.send_button}>Envoyer ma demande</button>

					{/*Legal notices*/}
					<div className={styles.legal_notices}>
						{/*Title*/}
						<span className={styles.title}>Mentions légales :</span>
						{/*Content*/}
						<span className={styles.content}>Nexity, en sa qualité de responsable de traitement, réalise des traitements de données à caractère personnel. Ces traitements sont rendus nécessaires pour répondre à votre demande de contact et sont fondés sur votre consentement préalable. Les données collectées sont destinées aux services concernés du groupe Nexity, et le cas échéant à ses sous-traitants et prestataires. Les sous-traitants et prestataires en question sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu'en conformité avec nos dispositions contractuelles et la législation applicable. Elles sont conservées pendant une durée ne pouvant excéder 3 ans à compter du dernier contact de votre part. Vous avez la possibilité d'exercer vos droits pour accéder, rectifier, effacer vos données, limiter leurs traitements, vous y opposer et demander la portabilité de celles-ci. Vous pouvez également définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel après votre décès. Ces droits s'exercent via le lien suivant : https://app.witik.io/fr/form/nexity/exercice-des-droits-rgpd ou par courrier postal à l'attention du Délégué à la Protection des Données (DPO) - Nexity SA - 19 Rue de Vienne - TSA 50029 - 75801 PARIS Cedex 08, accompagné d’une copie d’un titre d’identité. Vous pouvez également adresser une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés - 3 place de Fontenoy - TSA 80715 - 75334 PARIS Cedex 07. Enfin, nous vous informons de l'existence d'une liste d'opposition au démarchage téléphonique prévue par le Code de la Consommation à l'article L.223-1.</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Overlay
