//region Imports
import {animated, useSpring}                        from '@react-spring/three'
import {Environment, PresentationControls}          from '@react-three/drei'
import {useFrame, useThree}                         from '@react-three/fiber'
import {createUseGesture, pinchAction, wheelAction} from '@use-gesture/react'
import {gsap}                                       from 'gsap'
import {folder, useControls}                        from 'leva'
import {Suspense, useEffect, useRef, useState}      from 'react'
import Z_Apartment                                  from '../appartment/Z_Apartment'
//endregion

//region Default values
// Polar
const defaultPolar = [
	Math.PI
	/ 4,
	Math.PI
	/ 2,
]
// Azimuth
// 2 turn max (one turn on each side)
const defaultAzimuth = [
	-2
	* Math.PI,
	2
	* Math.PI,
]
// Zoom
const generalZoom    = 1.5
const roomsZoom      = 1

//region Camera zoom
const defaultCameraZoom = 1
const maxCameraZoom     = 1.8
const pinchMin          = 1
const pinchMax          = 3
const wheelMin          = -2_000
const wheelMax          = 0
//endregion
//endregion

const useGesture = createUseGesture([
	                                    pinchAction,
	                                    wheelAction,
                                    ])

const Z_Room_Canvas = ({
	                       modelUrl,
	                       room,
	                       canvasRef,
                       }) => {
	//region References
	const groupRef = useRef()
	//endregion

	//region States
	const [modelLoaded, setModelLoaded] = useState(false)

	const [cameraZoom, setCameraZoom] = useState(1.3)
	const [pinching, setPinching]     = useState(false)
	//endregion

	//region Gestures
	useGesture(
		{
			onPinchStart: () => {
				setPinching(true)
			},
			onPinchEnd:   () => {
				setPinching(false)
			},
			onPinch:      ({
				               offset: [s],
			               }) => {
				const currentCameraZoom = defaultCameraZoom
				                          + (
					                          maxCameraZoom
					                          * (
						                          s
						                          - pinchMin
					                          )
					                          / (
						                          pinchMax
						                          - pinchMin
					                          )
				                          )
				setCameraZoom(currentCameraZoom)
			},
			onWheel:      ({
				               event,
				               offset: [, oy],
			               }) => {
				event.preventDefault()

				const currentCameraZoom = defaultCameraZoom
				                          + (
					                          maxCameraZoom
					                          * (
						                          oy
						                          / (
							                          wheelMin
						                          )
					                          )
				                          )
				setCameraZoom(currentCameraZoom)
			},
		},
		{
			target: canvasRef,
			pinch:  {
				scaleBounds: {
					min: pinchMin,
					max: pinchMax,
				},
				rubberband:  true,
			},
			wheel:  {
				bounds:       {
					top:    wheelMin,
					bottom: wheelMax,
				},
				eventOptions: {
					passive: false,
				},
				rubberband:   true,
			},
		},
	)
	//endregion

	//region Springs
	// Default value is 0 to animate (scale up) when model appears
	const [currentCameraZoom, currentCameraZoomApi] = useSpring(() => (
		{
			value: 0,
		}
	))
	//endregion

	//region Camera
	const {camera} = useThree()
	//endregion

	//region LEVA - Properties
	//region Environment props
	const {...environmentProps} = useControls(
		'Environment',
		{
			background: false,
			frames:     1,
			resolution: 32,
			files:      '/ThreeJS_Files/venice_sunset_1k.hdr',
			intensity:  {
				value: .6,
				min:   0,
				max:   1,
				step:  .1,
			},
		},
		{collapsed: true},
	)
	//endregion

	//region Effects props (Bloom)
	const {
		      activateEffects,
		      mipmapBlur,
		      intensity,
		      luminanceThreshold,
	      } = useControls(
		'Effects',
		{
			activateEffects: false,
			Bloom:           folder({
				                        mipmapBlur:         true,
				                        intensity:          {
					                        value: .1,
					                        min:   0,
					                        max:   1,
					                        step:  .01,
				                        },
				                        luminanceThreshold: 0,
			                        }),
		},
		{collapsed: true},
	)
	//endregion

	//region Presentation controls props
	useFrame((state, delta) => {
		setPresentationControlsProps({
			                             rotation: [
				                             presentationControlsRotation.current.x,
				                             presentationControlsRotation.current.y,
				                             presentationControlsRotation.current.z,
			                             ],
		                             })
	})

	const presentationControlsRotation                                   = useRef([
		                                                                              0,
		                                                                              Math.PI,
		                                                                              0,
	                                                                              ])
	const [{...presentationControlsProps}, setPresentationControlsProps] = useControls(
		'Presentation controls',
		() => (
			{
				snap:     true,
				polar:    defaultPolar,
				azimuth:  defaultAzimuth,
				zoom:     generalZoom,
				rotation: [
					0,
					Math.PI,
					0,
				],
			}
		),
	)
	//endregion

	//region Group props
	const [{...groupProps}, setGroupProps] = useControls(
		'General group',
		() => (
			{
				position: [
					0,
					0,
					0,
				],
			}
		),
	)
	//endregion

	const updateLevaProps = () => {
		// Update group props
		setGroupProps({
			              position: groupRef.current.position.toArray(),
		              })
	}
	//endregion

	//region Handlers
	//region Room changed
	useEffect(
		() => {
			// Log selected room
			console.log(room)

			switch (room?.id) {
				/*TODO: Add this data into the API*/
				// 	Global view
				case 1:
					setPresentationControlsProps({
						                             polar:   [
							                             -.4,
							                             Math.PI
							                             / 2
							                             - .8,
						                             ],
						                             azimuth: defaultAzimuth,
						                             zoom:    generalZoom,
					                             })
					break
				default:
					setPresentationControlsProps({
						                             polar:   defaultPolar,
						                             azimuth: defaultAzimuth,
						                             zoom:    roomsZoom,
					                             })
					break
			}

			// Change snap from room data
			// Change rotation from room data
			setPresentationControlsProps({
				                             snap:  room?.snap, // rotation: room.rotation,
				                             polar: [
					                             -room.rotation[0]
					                             + .4,
					                             Math.PI
					                             / 2
					                             - room.rotation[0],
				                             ],
			                             })

			if (room) {
				const timeline = gsap.timeline({defaults: {duration: 1}})
				timeline.to(
					        groupRef.current.position,
					        {
						        x: room.group_position[0],
						        y: room.group_position[1],
						        z: room.group_position[2],
					        },
				        )
				        .to(
					        presentationControlsRotation.current,
					        {
						        x: room.rotation[0],
						        y: room.rotation[1],
						        z: room.rotation[2],
					        },
					        '<',
				        )
				        .call(
					        () => {
						        updateLevaProps()
					        },
					        null,
					        '>',
				        )
			}
		},
		[
			room,
			setGroupProps,
			setPresentationControlsProps,
		],
	)
	//endregion

	//region Component load
	const setCameraFovFromWindowWidth = () => {
		const isMobile = window.innerWidth
		                 < 768
		camera.fov     =
			isMobile
			? 80
			: 75
	}

	useEffect(
		() => {
			setCameraFovFromWindowWidth()

			// Change camera fov on window resize
			window.addEventListener(
				'resize',
				() => {
					setCameraFovFromWindowWidth()
				},
			)

			//region Deactivate pinch gesture
			const handler = e => e.preventDefault()
			document.addEventListener(
				'gesturestart',
				handler,
			)
			document.addEventListener(
				'gesturechange',
				handler,
			)
			document.addEventListener(
				'gestureend',
				handler,
			)
			return () => {
				document.removeEventListener(
					'gesturestart',
					handler,
				)
				document.removeEventListener(
					'gesturechange',
					handler,
				)
				document.removeEventListener(
					'gestureend',
					handler,
				)
			}
			//endregion
		},
		[],
	)
	//endregion

	//region Camera zoom
	useEffect(
		() => {
			// Only if the model is loaded
			if (modelLoaded) {
				currentCameraZoomApi.start({value: cameraZoom})
			}
		},
		[
			cameraZoom,
			modelLoaded,
		],
	)
	//endregion
	//endregion

	return (
		<animated.group
			position={[
				0,
				0,
				-10,
			]}
			{...groupProps}
			scale={currentCameraZoom.value}
			ref={groupRef}
		>
			<PresentationControls
				global
				//				enabled={ !pinching }
				{...presentationControlsProps}
			>
				{/* Environment */}
				<Environment
					{...environmentProps}
				/>

				{/* region Effects - Bloom */}
				{/* Bloom effect if effects are active */}
				{/*{activateEffects
				 && <EffectComposer>
				 <Bloom
				 mipmapBlur={mipmapBlur}
				 intensity={intensity}
				 luminanceThreshold={luminanceThreshold}
				 />
				 </EffectComposer>}*/}
				{/* endregion */}

				{/* Apartment model */}
				<Suspense fallback={null}>
					<Z_Apartment
						modelUrl={modelUrl}
						room={room}
						envMapIntensity={environmentProps.intensity}
						setModelLoaded={setModelLoaded}
						roomLightDistanceScale={cameraZoom}
					/>
				</Suspense>
			</PresentationControls>
		</animated.group>
	)
}

export default Z_Room_Canvas
