//region Imports
import {useGLTF, useHelper} from '@react-three/drei'
import {useControls}        from 'leva'
import {useEffect, useRef}  from 'react'
import {PointLightHelper}   from 'three'
import gsap                 from 'gsap/dist/gsap'
//endregion

const Z_Apartment = ({
	                     // Model URL
	                     modelUrl, // Current selected room
	                     room, // Intensity of the environment light on the materials
	                     envMapIntensity,
	                     setModelLoaded,
	                     roomLightDistanceScale,

	                     // Other properties
	                     ...props
                     }) => {

	//region References
	// Room light ref
	const roomLightRef = useRef()

	// Apartment model ref
	const apartRef = useRef()
	//endregion

	//region Load model
	const {
		      scene,
		      materials,
	      } = useGLTF(modelUrl)
	//endregion

	//region LEVA - Properties
	//region Apartment properties
	const [{...apartmentProps}, setApartmentProps] = useControls(
		'Apartment',
		() => (
			{
				position: [
					0,
					0,
					0,
				],
			}
		),
	)
	//endregion

	//region Directional light properties
	const [
		      {
			      showHelper: showDirectionalLightHelper,
			      ...         roomLightProps
		      }, setRoomLightProps,
	      ] = useControls(
		'Room light',
		() => (
			{
				showHelper: false,
				color:      'white',
				distance:   5,
				decay:      2,
				penumbra:   {
					value: 0,
					min:   0,
					max:   1,
					step:  .001,
				},
				position:   [
					0,
					0,
					0,
				],
				intensity:  2,
			}
		),
		{collapsed: true},
	)
	//endregion

	const updateLevaProps = () => {
		// Update group props
		setApartmentProps({
			                  position: apartRef.current.position.toArray(),
		                  })
	}
	//endregion

	//region Handlers
	//region Room changed or Room light distance scale changed
	// Camera animation
	useEffect(
		() => {
			if (room) {
				setRoomLightProps({
					                  position:  room.light_position,
					                  distance:  room.light_distance
					                             + (
						                             (
							                             roomLightDistanceScale
							                             - 1
						                             )
						                             * room.light_distance
						                             / 2
					                             ),
					                  intensity: room.light_intensity,
				                  })
			}
		},
		[
			room,
			roomLightDistanceScale,
		],
	)
	//endregion

	//region Room changed
	useEffect(
		() => {
			if (room) {
				const timeline = gsap.timeline({defaults: {duration: 1}})
				timeline.to(
					        apartRef.current.position,
					        {
						        x: room.position[0],
						        y: room.position[1],
						        z: room.position[2],
					        },
				        )
				        .call(
					        () => {
						        updateLevaProps()
					        },
					        null,
					        '>',
				        )
			}
		},
		[room],
	)
	//endregion

	//region Materials or envMapIntensity changed
	useEffect(
		() => {
			// Set material envMapIntensity : intensity of the environment light on the materials
			Object.entries(materials)
			      .forEach(([key, mat]) => {
				      mat.envMapIntensity = envMapIntensity
			      })
		},
		[
			envMapIntensity,
			materials,
		],
	)

	useEffect(
		() => {
			const floor_and_walls_material       = materials['Baked walls']
			floor_and_walls_material.flipY       = false
			floor_and_walls_material.needsUpdate = true
		},
		[materials],
	)
	// endregion

	//region Model loaded
	useEffect(
		() => {
			if (scene) {
				setModelLoaded(true)
			}
		},
		[scene],
	)
	//endregion
	// endregion

	//region Helpers
	useHelper(
		showDirectionalLightHelper
		&& roomLightRef,
		PointLightHelper,
	)
	//endregion

	return (
		<>
			{/* Light */}
			<pointLight
				ref={roomLightRef} {...roomLightProps}
			/>

			{/* Model */}
			<primitive
				object={scene}
				{...apartmentProps}
				ref={apartRef}

				// Default properties
				{...props}
			/>
		</>
	)
}

export default Z_Apartment
