import styles                                                                                from '../styles/Layout.module.sass'
import {useEffect, useState}                                                                 from 'react'
import App                                                                                   from '../App'
import {FaBars, FaCaretDown, FaCaretLeft, FaChevronLeft, FaHeart, FaHistory, FaHome, FaUser} from 'react-icons/fa'
import NexityLogo                                                                            from '../images/nexity_logo.png'
import Overlay                                                                               from './Overlay'
import axios                                                                                 from 'axios'
import qs                                                                                    from 'qs'
import {useGLTF}                                                                             from '@react-three/drei'
import cn                                                                                    from 'classnames'
//region Images
//region Equipments

//endregion
//region Transports import {Overlay} from './Overlay'
//endregion
//endregion

const getApartmentData = async () => {
	axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`

	const query = qs.stringify(
		{
			populate: {
				apartment: {
					fields: [
						'title',
					],

					populate: {
						glb_file: {
							fields: [
								'id',
								'url',
							],
						},

						cameras: {
							fields: [
								'*',
							],
						},
					},
				},
			},
		},
		{encodeValuesOnly: true},
	)

	let apartmentData = {
		glb_file: '',
		cameras:  [],
	}
	await axios.get(`${process.env.REACT_APP_STRAPI_API}/api/setting?${query}`)
	           .then(res => {
		           const {
			                 glb_file,
			                 cameras,
		                 } = res.data.data.attributes.apartment.data.attributes

		           // Set glb file
		           apartmentData.glb_file = `${process.env.REACT_APP_STRAPI_API}${glb_file.data.attributes.url}`
		           // Preload GLB File
		           useGLTF.preload(apartmentData.glb_file)

		           cameras.forEach(camera => {
			           let cameraData = {}

			           cameraData.id   = camera.id
			           cameraData.name = camera.name

			           // Apartment Position
			           cameraData.position = [
				           camera.position_x,
				           camera.position_y,
				           camera.position_z,
			           ]

			           // Presentation controls Rotation
			           cameraData.rotation = [
				           camera.rotation_x,
				           camera.rotation_y,
				           camera.rotation_z,
			           ]

			           // Group position
			           cameraData.group_position = [
				           camera.group_position_x,
				           camera.group_position_y,
				           camera.group_position_z,
			           ]

			           // Light position
			           cameraData.light_position = [
				           camera.light_position_x,
				           camera.light_position_y,
				           camera.light_position_z,
			           ]

			           // Light distance
			           cameraData.light_distance = camera.light_distance

			           // Light intensity
			           cameraData.light_intensity = camera.light_intensity

			           // Camera snap
			           cameraData.snap =
				           camera.snap
				           ?? true

			           /*Add the camera into the apartment cameras list*/
			           apartmentData.cameras.push(cameraData)
		           })
	           })

	return apartmentData
}

const Layout = () => {
	//region States
	const [pageToShow, setPageToShow] = useState('building-page')
	const [fullscreen, setFullScreen] = useState(window.location.hash
	                                             === '#3D')

	// If true, show the iframe, else show an image with some overlay representing selectable apartments
	const [showIframe, setShowIframe] = useState(true)

	// Current overlay tab
	const [currentTab, changeCurrentTab] = useState('program')

	// Model url
	const [modelUrl, setModelUrl]         = useState('')
	// Store all rooms data
	const [rooms, setRooms]               = useState([])
	// Selected room
	const [selectedRoom, setSelectedRoom] = useState(1)

	const [numberOfRooms, setNumberOfRooms] = useState(null)
	//endregion

	//region Handlers
	// Component load -> Get all rooms data
	useEffect(
		() => {
			const loadRooms = async () => {
				const data = await getApartmentData()

				setModelUrl(data.glb_file)

				setRooms(data.cameras)

				// Set selected camera to first apartment camera
				setSelectedRoom(data.cameras[0].id)

				// DEBUG - 3D ROOM
				// setFullScreen(true)
				// setPageToShow('room-page')
			}

			loadRooms()
				.then()
		},
		[],
	)
	//endregion

	return (
		<div
			className={cn(
				styles.page_content,
				{
					[styles.fullscreen]:   fullscreen,
					[styles.is_plan_page]: pageToShow
					                       === 'plan-page',
				},
			)}
		>
			{/*Header*/}
			<Header
				fullscreen={fullscreen}
				setFullScreen={setFullScreen}
			/>

			{/*Content with current page component*/}
			<main>
				{fullscreen
				 && <Overlay
					 pageToShow={pageToShow}
					 setPageToShow={setPageToShow}
					 showIframe={showIframe}
					 setShowIframe={setShowIframe}
					 rooms={rooms}
					 selectedRoom={selectedRoom}
					 setSelectedRoom={setSelectedRoom}
					 selectedNumberOfRooms={numberOfRooms}
					 setSelectedNumberOfRooms={setNumberOfRooms}
					 currentTab={currentTab}
					 changeCurrentTab={changeCurrentTab}
				 />}
				<App
					modelUrl={modelUrl}
					pageToShow={pageToShow}
					setPageToShow={setPageToShow}
					fullscreen={fullscreen}
					setFullScreen={setFullScreen}
					showIframe={showIframe}
					rooms={rooms}
					selectedRoom={selectedRoom}
					numberOfRooms={currentTab
					               === 'apartments'
					               ? numberOfRooms
					               : null}
				/>
			</main>

			<Footer/>
		</div>
	)
}

const Header = ({
	                fullscreen,
	                setFullScreen,
                }) => {
	return <header>
		{/*Logo*/}
		<div className={styles.logo}>
			<img
				src={NexityLogo}
				alt={'Logo Nexity'}
			/>

			{fullscreen
			 && /*Go back to research page*/
			 <div
				 className={styles.back_button}

				 //region Handlers
				 onClick={() => {
					 setFullScreen(false)
				 }}
				 //endregion
			 >
				 <FaChevronLeft className={styles.icon}/>
				 <span className={styles.text}>Retour à la recherche</span>
			 </div>}
		</div>

		{!fullscreen
		 && /*Filters*/
		 <div className={styles.filters}>
			 {/*Project*/}
			 <div className={styles.project}>
				 {/*Top*/}
				 <div className={styles.top}>
					 {/*Name*/}
					 <span className={styles.name}>Projet</span>
					 {/*Number*/}
					 <div className={styles.number}>
						 <span className={styles.value}>3</span>
					 </div>
				 </div>

				 {/*Bottom*/}
				 <div className={styles.bottom}>
					 {/*Description*/}
					 <span className={styles.description}>Acheter, Appartement...</span>
				 </div>

				 {/*Right icon*/}
				 <div className={styles.right_icon}>
					 <FaCaretDown/>
				 </div>
			 </div>

			 {/*Localisation*/}
			 <div className={styles.localisation}>
				 {/*Top*/}
				 <div className={styles.top}>
					 {/*Name*/}
					 <span className={styles.name}>Localisation</span>
					 {/*Number*/}
					 <div className={styles.number}>
						 <span className={styles.value}>3</span>
					 </div>
				 </div>

				 {/*Bottom*/}
				 <div className={styles.bottom}>
					 {/*Description*/}
					 <span className={styles.description}>Issy les moulineaux, Paris...</span>
				 </div>

				 {/*Right icon*/}
				 <div className={styles.right_icon}>
					 <FaCaretDown/>
				 </div>
			 </div>

			 {/*Caractéristiques*/}
			 <div className={styles.localisation}>
				 {/*Top*/}
				 <div className={styles.top}>
					 {/*Name*/}
					 <span className={styles.name}>Caractéristiques</span>
					 {/*Number*/}
					 <div className={styles.number}>
						 <span className={styles.value}>2</span>
					 </div>
				 </div>

				 {/*Bottom*/}
				 <div className={styles.bottom}>
					 {/*Description*/}
					 <span className={styles.description}>Piscine, Packing/Box</span>
				 </div>

				 {/*Right icon*/}
				 <div className={styles.right_icon}>
					 <FaCaretDown/>
				 </div>
			 </div>
		 </div>}


		{/*Links*/}
		<div className={styles.links}>
			{/*History*/}
			<div className={styles.history}>
				<div className={styles.icon}>
					<FaHistory/>
				</div>

				<span className={styles.text}>Historique</span>
			</div>

			{/*Favorites*/}
			<div className={styles.favorites}>
				<div className={styles.icon}>
					<FaHeart/>
				</div>
				<span className={styles.text}>Favoris</span>
			</div>

			{/*My account*/}
			<div className={styles.my_account}>
				<div className={styles.icon}>
					<FaUser/>
				</div>
				<div className={styles.text}>
					<span>Mon compte</span>
				</div>
			</div>

			{/*Menu*/}
			<div className={styles.menu}>
				<div className={styles.icon}>
					<FaBars/>
				</div>

				{/*Text*/}
				<span className={styles.text}>Menu</span>
			</div>

			{/*Spacer*/}
			<div className={styles.spacer}/>
		</div>
	</header>
}

const Footer = () => {
	return (
		<footer>
			{/*Breadcrumb*/}
			<div className={styles.breadcrumb}>
				{/*Home icon*/}
				<FaHome className={styles.home_icon}/>

				{/*Immobilier*/}
				<span>Immobilier</span>

				{/*Achat*/}
				<span>Achat</span>

				{/*Appartement*/}
				<span>Appartement</span>

				{/*Île-de-France*/}
				<span>Île-de-France</span>

				{/*Département*/}
				<span>Département</span>

				{/*Ovation Magellan*/}
				<div className={styles.current_tab}>
					<span>Ovation Magellan</span>
					<FaCaretLeft className={styles.icon}/>
				</div>
			</div>

			{/*Links*/}
			<div className={styles.links}>
				<span>Mentions légales</span>
				<span>Conditions générales</span>
				<span>Politique de protection des données</span>
				<span>Centre d’aide</span>
				<span>Service client</span>
				<span>Gestion des cookies</span>
			</div>
		</footer>
	)
}
export default Layout
