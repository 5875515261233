//region Imports
import React, {useRef, useState} from 'react'
import 'swiper/css'
import Z_Room_Canvas             from '../../components/room/Z_Room_Canvas'
import styles                    from '../../styles/Z_Room.module.sass'
import Z_Default_Canvas          from '../Z_Default_Canvas'
//endregion

const Z_Room_Page = ({
	                     modelUrl,
	                     rooms,
	                     selectedRoom,
                     }) => {

	//region References
	const canvasRef = useRef()
	//endregion

	//region States
	// Contact us section active or not
	const [contactUsActive, setContactUsActive] = useState(false)
	const [vrActive, setVrActive]               = useState(false)

	// Data shown in the top section
	const [topData, setTopData] = useState({
		                                       numberOfRooms: 2,
		                                       surface:       58,
		                                       price:         '312 000',
	                                       })
	//endregion

	return (
		<Z_Default_Canvas
			canvasStyle={styles.canvas}
			canvasRef={canvasRef}
		>
			<Z_Room_Canvas
				modelUrl={modelUrl}

				room={rooms?.find(elt => elt.id
				                         === selectedRoom)}
				canvasRef={canvasRef}
			/>
		</Z_Default_Canvas>
	)
}

export default Z_Room_Page
