import Z_Room_Page         from './components/room/Z_Room_Page'
import ListOfAppApartments from './components/ListOfAppApartments'
import './styles/globals.sass'
import Plan_Page           from './components/Plan_Page'
import {useGLTF}           from '@react-three/drei'
import HabiteoBuilding     from './components/HabiteoBuilding'
import {useEffect}         from 'react'

const debug  = window.location.hash
               === '#debug'
window.debug = debug

export default function App({
	                            modelUrl,
	                            pageToShow,
	                            setPageToShow,
	                            fullscreen,
	                            setFullScreen,
	                            showIframe,
	                            rooms,
	                            selectedRoom,
	                            numberOfRooms,
                            }) {

	useEffect(
		() => {
			document.body.style.overflow =
				fullscreen
				? 'hidden'
				: 'auto'
		},
		[fullscreen]
	)

	return (
		<>
			{fullscreen
			 ? <div className={'fullscreen_section'}>
				 {pageToShow
				  === 'building-page'
				  && <HabiteoBuilding
					  clickAction={() => setPageToShow('room-page')}
					  numberOfRooms={numberOfRooms}
				  />}

				 {pageToShow
				  === 'room-page'
				  && <Z_Room_Page
					  modelUrl={modelUrl}

					  rooms={rooms}
					  selectedRoom={selectedRoom}
				  />}


				 {pageToShow
				  === 'neighborhood-page'
				  && <iframe
					  style={{border: 0}}
					  loading='lazy'
					  allowFullScreen
					  src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJU80fINxl5kcRbbH1oLa67m4&key=AIzaSyA6Du0QzMyDQRvc3JtUQNURjk_vKo48BIw'
				  ></iframe>}

				 {pageToShow
				  === 'plan-page'
				  && <Plan_Page/>}
			 </div>
			 : <ListOfAppApartments
				 pageToShow={pageToShow}
				 setFullScreen={setFullScreen}
			 />}
		</>
	)
}


if (process.env.NODE_ENV
    === 'production') {
	// Preload building model
	useGLTF.preload('/ThreeJS_Files/nexity-charenton.glb')
}
