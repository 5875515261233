//region Imports
import {animated, config, useSpring}  from '@react-spring/three'
import {Line, Plane, useCursor}       from '@react-three/drei'
import {useEffect, useMemo, useState} from 'react'
//endregion

export const Z_Building_Appartment = ({
	                                      planeArgs,
	                                      position,
	                                      rotationY = 0,
	                                      clickAction,
	                                      selected,
	                                      setSelectedApartment,
	                                      disableSelected,
	                                      disabled,
                                      }) => {

	// Hover state
	const [hover, setHover] = useState(false)
	useCursor(
		hover,
		'pointer',
	)

	//region Spring
	const [groupScale, api] = useSpring(() => (
		{
			value: 1,
		}
	))
	//endregion

	//region Memos
	const linePoints = useMemo(
		() => {
			const linePoints = []

			// Plane dimensions
			const width  = planeArgs[0]
			const height = planeArgs[1]

			// Top left
			linePoints.push([
				                width
				                / 2,
				                height
				                / 2,
				                0,
			                ])

			// Top right
			linePoints.push([
				                -width
				                / 2,
				                height
				                / 2,
				                0,
			                ])

			// Bottom right
			linePoints.push([
				                -width
				                / 2,
				                -height
				                / 2,
				                0,
			                ])

			// Bottom left
			linePoints.push([
				                width
				                / 2,
				                -height
				                / 2,
				                0,
			                ])

			// Top left
			linePoints.push([
				                width
				                / 2,
				                height
				                / 2,
				                0,
			                ])

			return linePoints
		},
		[planeArgs],
	)
	//endregion

	//region Handlers
	// If the selected apartment becomes disabled => set selected apartment to null
	useEffect(
		() => {
			if (disabled
			    && selected) {
				disableSelected()
			}
		},
		[
			selected,
			disabled,
		],
	)

	useEffect(
		() => {
			if (selected) {
				api.start({
					          to: [
						          {
							          value:  1.05,
							          config: config.wobbly,
						          },
						          {
							          value:  1,
							          config: config.default,
						          },
					          ],
				          })
			}
		},
		[selected],
	)

	// useEffect(
	// 	() => {
	// 		api.start({
	// 			          to: [
	// 				          {
	// 					          value:  disabled
	// 					                  ? 0
	// 					                  : 1,
	// 					          config: config.wobbly,
	// 				          },
	// 			          ],
	// 		          })
	// 	},
	// 	[disabled],
	// )
	//endregion

	return (
		<animated.group
			position={position}
			rotation-y={rotationY}
			scale={groupScale.value}

			//region Handlers
			onClick={() => {
				if (!disabled) {
					clickAction()
				}
			}}

			onPointerOver={() => {
				setHover(!disabled)
			}}
			onPointerOut={() => {
				setHover(false)
			}}
			//endregion
		>
			<group
				name={'selector'}
				position={[
					-planeArgs[0]
					/ 2,
					planeArgs[1]
					/ 2,
					.01,
				]}
			>
				<mesh
					name={'outside_circle'}
				>
					<circleGeometry
						args={[
							.1,
							32,
						]}
					/>
					<meshBasicMaterial color={'grey'}/>
				</mesh>
				<mesh
					name={'inside_circle'}
					position-z={.001}
					scale={.8}
				>
					<circleGeometry
						args={[
							.1,
							32,
						]}
					/>
					<meshBasicMaterial
						color={disabled
						       ? '#292933'
						       : hover
						         ? '#7380EA'
						         : 'white'}
					/>
				</mesh>
			</group>
			<Plane
				args={planeArgs}
			>
				<meshStandardMaterial
					transparent
					opacity={.2}
					color={disabled
					       ? '#292933'
					       : hover
					         ? '#7380EA'
					         : '#d3d6eb'}
				/>
			</Plane>
			<Line
				points={linePoints}
				color={disabled
				       ? '#292933'
				       : hover
				         ? '#7380EA'
				         : '#d3d6eb'}
				lineWidth={4}
			/>
		</animated.group>
	)
}

const Z_Building_Appartments = ({
	                                buildingRef,
	                                selectedNumberOfRoomsId,
	                                surfaceValues,
	                                selectedApartment,
	                                setSelectedApartment,
                                }) => {
	//region Data
	const buildings = [
		//Left
		{
			planeArgs:       [
				1.5,
				.25,
			],
			position:        [
				-.5,
				1.65,
				1.1,
			],
			rotationY:       0,
			numberOfRoomsId: 2,
			surface:         75,
		},
		{
			planeArgs:       [
				1.5,
				.25,
			],
			position:        [
				-.5,
				1.1,
				1.1,
			],
			rotationY:       0,
			numberOfRoomsId: 1,
			surface:         25,
		}, // Center
		{
			planeArgs:       [
				1.25,
				.25,
			],
			position:        [
				1,
				1.95,
				1.44,
			],
			rotationY:       0,
			numberOfRoomsId: 2,
			surface:         75,
		},
		{
			planeArgs:       [
				1.25,
				.25,
			],
			position:        [
				1,
				.82,
				1.44,
			],
			rotationY:       0,
			numberOfRoomsId: 1,
			surface:         50,
		}, // Right side
		{
			planeArgs:       [
				2.05,
				.25,
			],
			position:        [
				1.69,
				1.65,
				.2,
			],
			rotationY:       Math.PI
			                 / 2,
			numberOfRoomsId: 0,
			surface:         30,
		},
		{
			planeArgs:       [
				2.05,
				.25,
			],
			position:        [
				1.69,
				1.1,
				.2,
			],
			rotationY:       Math.PI
			                 / 2,
			numberOfRoomsId: 0,
			surface:         90,
		},
	]
	//endregion

	return (
		<group name={'apartments'}>
			{buildings.map(({
				                numberOfRoomsId,
				                surface,
				                ...buildingProps
			                }, index) => {
				return (
					<Z_Building_Appartment
						key={index}
						{...buildingProps}
						selected={index
						          === selectedApartment}
						setSelectedApartment={() => {
							setSelectedApartment(index)
						}}
						disableSelected={() => {
							setSelectedApartment(null)
						}}
						buildingRef={buildingRef}
						disabled={numberOfRoomsId
						          !== selectedNumberOfRoomsId
						          || surface
						          < surfaceValues.min
						          || surface
						          > surfaceValues.max}
					/>
				)
			})}
		</group>
	)

}

export default Z_Building_Appartments
