//region Imports
import styles                          from '../styles/ListOfAppApartments.module.sass'
import {FaBell, FaHeart, FaMoneyBill}  from 'react-icons/fa'
import cn                              from 'classnames'
import {useMemo}                       from 'react'
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api'
//endregion

const ListOfAppApartments = ({
	                             setFullScreen,
                             }) => {

	const apartments = useMemo(
		() => {
			return [
				{
					name:     'Les hautes maisons - 05',
					price:    '325 000',
					surface:  35,
					location: 'Coupvray (77)',
					type:     'avant-première',
					tags:     [],
					image:    '/images/apartments/apt1.jpg',
				},
				{
					name:     'Les terrasses du 5',
					price:    '393 000',
					surface:  35,
					location: 'Maison-Alfort (94)',
					type:     'ideal-investisseur',
					tags:     [],
					image:    '/images/apartments/apt2.jpg',
				},
				{
					name:     'Tilia',
					price:    '187 000',
					surface:  35,
					location: 'La Queue-En-Brie (94)',
					type:     'avant-première',
					tags:     [
						'Pinel',
					],
					image:    '/images/apartments/apt3.jpg',
				},
				{
					name:     'L\'observatoire',
					price:    '257 000',
					surface:  35,
					location: 'Deuil-La-Barre (95)',
					type:     'immobilier-neuf',
					tags:     [],
					image:    '/images/apartments/apt4.jpg',
				},
				{
					name:     'Les Jardins Mansart',
					price:    '413 000',
					surface:  35,
					location: 'Clamart (92)',
					type:     'immobilier-neuf',
					tags:     [
						'Pinel',
					],
					image:    '/images/apartments/apt5.jpg',
				},
				{
					name:     'Storia',
					price:    '216 000',
					surface:  35,
					location: 'Villiers sur marne (94)',
					type:     'ideal-investisseur',
					tags:     [
						'Pinel',
					],
					image:    '/images/apartments/apt6.jpg',
				},
				{
					name:     'Residence O\'centre',
					price:    '306 000',
					surface:  35,
					location: 'Villiers-Le-Bel (95)',
					type:     'avant-première',
					tags:     [
						'Pinel',
						'TVA 5,5%',
					],
					image:    '/images/apartments/apt7.jpg',
				},
				{
					name:     'Harmonie',
					price:    '364 000',
					surface:  35,
					location: 'Argenteuil (95)',
					type:     'ideal-investisseur',
					tags:     [
						'Pinel',
					],
					image:    '/images/apartments/apt8.jpg',
				},
				{
					name:     'Le Clos Duroy',
					price:    '221 000',
					surface:  35,
					location: 'Le Blanc-Mesnil (93)',
					type:     'avant-première',
					tags:     [
						'Pinel',
						'TVA 5,5%',
					],
					image:    '/images/apartments/apt9.jpg',
				},
				{
					name:     'Carre Pinson',
					price:    '268 000',
					surface:  35,
					location: 'Montmagny (95)',
					type:     'ideal-investisseur',
					tags:     [
						'Pinel',
						'TVA 5,5%',
					],
					image:    '/images/apartments/apt10.jpg',
				},
			]
		},
		[],
	)

	return (
		<div className={styles.list_of_apartments_section}>
			{/*Text*/}
			<div className={styles.text}>
				<h4>Acheter appartement à Paris (18, 17), Issy les Moulineaux</h4>
			</div>

			{/*List of apartments*/}
			<div className={styles.list_of_apartments}>
				<div className={styles.top}>
					<div className={styles.number_of_results}>
						<div className={styles.number}>250</div>
						<span className={styles.text}>résultats</span>
					</div>

					<div className={styles.sort_by}>
						<span className={styles.text}>Tri</span>
					</div>
				</div>

				{/*Show the list of apartments cards*/}
				<div className={styles.list}>
					{apartments.map((item, index) => {

						return <div
							className={styles.apartment}
							key={index}

							// On click -> Set the full screen state to true
							onClick={() => setFullScreen(true)}
						>
							{/*Overlay*/}
							<div className={styles.overlay}>
								{/*Tip*/}
								{item.type
								 === 'avant-première'
								 && // Avant-première
								 <div
									 className={cn(
										 styles.tip,
										 styles.avant_premiere,
									 )}
								 >
									 <FaBell className={styles.icon}/>
									 <span className={styles.text}>Avant première</span>
								 </div>}
								{item.type
								 === 'ideal-investisseur'
								 && // Idéal investisseur
								 <div
									 className={cn(
										 styles.tip,
										 styles.ideal_investisseur,
									 )}
								 >
									 <FaMoneyBill className={styles.icon}/>
									 <span className={styles.text}>Idéal investisseur</span>
								 </div>}

								{/*Favorite icon*/}
								<FaHeart className={styles.favorite_icon}/>
							</div>

							{/*Image*/}
							<div className={styles.image}>
								<img
									src={item.image}
									alt='Image de l&apos;appartement'
								/>
							</div>

							{/*Content*/}
							<div className={styles.content}>
								{/*Tags*/}
								<div className={styles.tags}>
									{item.tags.map((tag, index) => <div
										className={styles.tag}
										key={index}
									>{tag}</div>)}
								</div>

								{/*General information*/}
								<div className={styles.general_information}>
									{/*Title*/}
									<div className={styles.title}>{item.name}</div>

									{/*Category and surface*/}
									<div className={styles.category_and_surface}>
										{/*Category*/}
										<div className={styles.category}>Immeuble neuf</div>

										{/*Divider*/}
										<div className={styles.divider}></div>

										{/*Surface*/}
										<div className={styles.surface}>{item.surface
										                                 ?? 0}m²
										</div>
									</div>

									{/*Location*/}
									<span className={styles.location}>{item.location}</span>

									{/*Price*/}
									<span className={styles.price}>{item.price}€</span>
								</div>
							</div>
						</div>
					})}
				</div>
			</div>

			{/*Map on the right of the screen*/}
			<div className={styles.map}>
				<MapWrapped
					googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=Function.prototype`}
				/>
			</div>
		</div>
	)
}


const MapWrapped = props => {

	const markerArray = [
		/*Observatoire*/
		{
			lat: 48.97016744830731,
			lng: 2.312113582840849,
		},
		/*Résidence O'centre*/
		{
			lat: 48.994578364768294,
			lng: 2.4107259472590283,
		},
		/*Harmonie*/
		{
			lat: 48.94248702396047,
			lng: 2.241019765530044,
		},
		/*Les terrasses du 5*/
		{
			lat: 48.801685098646516,
			lng: 2.427378259506826,
		},
		/*Tilia*/
		{
			lat: 48.789550442683336,
			lng: 2.5774636401719917,
		},

		/*Fictifs*/
		{
			lat: 48.80889791665567,
			lng: 2.127821346673213,
		},

		{
			lat: 48.80762194660033,
			lng: 2.2623266130896287,
		},

		{
			lat: 48.78159496220103,
			lng: 2.376835868959516,
		},

		{
			lat: 48.88078511036447,
			lng: 2.3247901914953353,
		},

		{
			lat: 48.872817107222204,
			lng: 2.1855934771162455,
		},

		{
			lat: 48.793465558250304,
			lng: 2.542488412605063,
		},

		{
			lat: 48.6487796470075,
			lng: 2.325404527498729,
		},

		{
			lat: 48.85048697541129,
			lng: 2.3803619319296714,
		},

		{
			lat: 48.84462371249214,
			lng: 2.387246822082137,
		},

		{
			lat: 48.85501720836458,
			lng: 2.3993966282335473,
		},

		{
			lat: 48.87686316311813,
			lng: 2.4010166023870685,
		},

		{
			lat: 48.894705827368085,
			lng: 2.4479958528391887,
		},

		{
			lat: 48.90295923425877,
			lng: 2.299768217791983,
		},

		{
			lat: 48.952314396114424,
			lng: 2.347693482468711,
		},

		{
			lat: 48.77811374807403,
			lng: 2.4009845111549875,
		},

		{
			lat: 48.82469874017544,
			lng: 2.353285750600678,
		},
	]

	const center = {
		lat: 48.866667,
		lng: 2.333333,
	}

	return (
		<div className={styles.google_map}>
			<LoadScript
				googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
			>
				<GoogleMap
					center={center}
					zoom={10}
				>
					{markerArray.map((marker, index) => (
						<Marker
							key={index}
							position={{
								lat: marker.lat,
								lng: marker.lng,
							}}

							icon={{
								url: '/google-map-marker.svg',
							}}
						/>
					))}
				</GoogleMap>
			</LoadScript>
		</div>
	)
}

export default ListOfAppApartments
