//region Imports
import {Loader}            from '@react-three/drei'
import {Canvas, useThree}  from '@react-three/fiber'
import {Leva, useControls} from 'leva'
import {Perf}              from 'r3f-perf'
import {useEffect}         from 'react'
//endregion

const Z_Default_Canvas = ({
	                          children,
	                          canvasStyle,
	                          canvasRef
                          }) => {

	return (
		<>
			{/* Leva */}
			<Leva
				collapsed={true}
				hidden={!window.debug}
			/>

			{/* Canvas */}
			<Canvas
				shadows={true}
				ref={canvasRef}
				className={canvasStyle}
			>
				{ /* region Performances */}
				{window.debug
				 && <Perf position={'top-left'}/>}
				{ /* endregion */}

				{children}
			</Canvas>

			{/* Loader - Shown during canvas model load */}
			<Loader/>
		</>
	)
}

export default Z_Default_Canvas
