import {PerspectiveCamera, PresentationControls, useGLTF} from '@react-three/drei'
import {Canvas}                                           from '@react-three/fiber'
import {Z_Building_Appartment}                            from './building/Z_Building_Appartments'
import {Leva, useControls}                                from 'leva'
import {useRef, useState}                                 from 'react'
import {useGesture}                                       from '@use-gesture/react'


//region Default values
const isDesktop         = window.innerWidth
                          > 1024
const defaultCameraZoom = isDesktop
                          > 1024
                          ? .8
                          : 1.5
const maxCameraZoom     = isDesktop
                          > 1024
                          ? 1.5
                          : 2
const pinchMin          = 1
const pinchMax          = 3
const wheelMin          = -2_000
const wheelMax          = 0
//endregion

const Building = ({
	                  clickAction,
	                  numberOfRooms,
	                  props,
                  }) => {

	//region Model
	const {
		      nodes,
		      materials,
	      } = useGLTF('/ThreeJS_Files/nexity-charenton.glb')
	//endregion

	//region DEBUG - LEVA
	//region Overlay props
	const currentOverlayProps = useControls(
		'Current overlay',
		{
			planeArgs: [
				.8,
				.3,
			],
			position:  [
				1.8,
				.9,
				1.75,
			],
			rotationY: -.58,
		},
	)
	//endregion
	//endregion

	const overlays = [
		{
			planeArgs:      [
				1.3,
				.3,
			],
			position:       [
				.2,
				1.48,
				1.15,
			],
			rotationY:      0,
			numbersOfRooms: 4,
		},
		{

			planeArgs:      [
				.8,
				.3,
			],
			position:       [
				1.8,
				.9,
				1.75,
			],
			rotationY:      -.58,
			numbersOfRooms: 3,
		},
		{

			planeArgs:      [
				.76,
				.3,
			],
			position:       [
				3.45,
				1.8,
				2.73,
			],
			rotationY:      -.58,
			numbersOfRooms: 3,
		},
	]

	return (
		<group {...props} dispose={null}>
			<group
				scale={.1}
			>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.baie_vitre.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						44.63,
						16.48,
						17.41,
					]}
					rotation={[
						0,
						-0.58,
						0.08,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.Beton_blanc.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-25.52,
						18.02,
						16.98,
					]}
					rotation={[
						Math.PI
						/ 2,
						0,
						-0.5,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.blanc.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-30.89,
						17.68,
						8.73,
					]}
					rotation={[
						Math.PI
						/ 2,
						0,
						-2.06,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.brique_grise.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-22.02,
						4.03,
						23.41,
					]}
					rotation={[
						Math.PI
						/ 2,
						0,
						-0.5,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.briques.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						8.62,
						16.53,
						-4.7,
					]}
					rotation={[
						-Math.PI
						/ 2,
						0,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.damier.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						16.05,
						6.03,
						-3.15,
					]}
					rotation={[
						Math.PI
						/ 2,
						0,
						0.58,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.Extra.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-18.99,
						13.82,
						15.33,
					]}
					rotation={[
						0,
						0.48,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.fenetre.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						4.18,
						17.46,
						-3.39,
					]}
					rotation={[
						-Math.PI
						/ 2,
						0,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.Porte_entree.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						8.07,
						2.43,
						8.76,
					]}
					rotation={[
						-Math.PI
						/ 2,
						0,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.rebord.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-11.32,
						13.26,
						13.52,
					]}
					rotation={[
						0,
						0.49,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.rebord_001.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-0.52,
						15.43,
						11.26,
					]}
					rotation={[
						Math.PI
						/ 2,
						0,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.rembarde.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-26.3,
						10.35,
						17.44,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.Sol.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						-3.88,
						0.38,
						11.21,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.vitre.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI}
					position={[
						37.79,
						17.5,
						15.26,
					]}
					rotation={[
						0,
						-0.56,
						-Math.PI
						/ 2,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.TOIT.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_specular}
					position={[
						-22.86,
						19.26,
						10.99,
					]}
					rotation={[
						0,
						-0.62,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.TOIT_2.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_specular}
					position={[
						-22.86,
						19.26,
						10.99,
					]}
					rotation={[
						0,
						-0.62,
						0,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.GRILLAGE.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_transparence}
					position={[
						12.4,
						1.76,
						-5.3,
					]}
					rotation={[
						-Math.PI
						/ 2,
						0,
						-0.58,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.mini_bariere.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_transparence}
					position={[
						-11.34,
						8.99,
						13.48,
					]}
					rotation={[
						Math.PI
						/ 2,
						0,
						-0.49,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.portail.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_transparence}
					position={[
						8.05,
						1.62,
						-8.28,
					]}
					rotation={[
						0,
						0.99,
						-Math.PI
						/ 2,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.portail_1.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_transparence}
					position={[
						-3.39,
						1.62,
						-12.95,
					]}
					rotation={[
						-Math.PI
						/ 2,
						1.57,
						0,
					]}
					scale={[
						1,
						1,
						0.77,
					]}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.portail_2.geometry}
					material={materials.SHADER_NEXITY_94_CHARENTON_LE_PONT_SEERI_transparence}
					position={[
						-34.52,
						1.62,
						-2.15,
					]}
					rotation={[
						-Math.PI,
						1.07,
						Math.PI
						/ 2,
					]}
				/>
			</group>

			{/*Overlays*/}
			<group name={'overlays'}>
				{overlays.map((overlay, index) => (
					<Z_Building_Appartment
						key={index}
						planeArgs={overlay.planeArgs}
						position={overlay.position}
						rotationY={overlay.rotationY}
						clickAction={clickAction}
						disabled={overlay.numbersOfRooms
						          !== numberOfRooms}
					/>
				))}
			</group>
		</group>
	)
}

const HabiteoBuilding = ({
	                         clickAction,
	                         numberOfRooms,
	                         props,
                         }) => {

	const canvasRef = useRef()

	const cameraControlsProps = useControls(
		'Camera',
		{
			position: [
				isDesktop
				? -1
				: 0,
				0,
				12,
			],
		},
	)

	const presentationControlsProps = useControls(
		'Presentation',
		{
			rotation: [
				.5,
				.1,
				0,
			],
		},
	)

	//region Camera zoom
	const [cameraZoom, setCameraZoom] = useState(defaultCameraZoom)

	//region Gestures
	useGesture(
		{
			onPinch: ({
				          offset: [s],
			          }) => {
				const currentCameraZoom = defaultCameraZoom
				                          + (
					                          maxCameraZoom
					                          * (
						                          s
						                          - pinchMin
					                          )
					                          / (
						                          pinchMax
						                          - pinchMin
					                          )
				                          )
				setCameraZoom(currentCameraZoom)
			},
			onWheel: ({
				          event,
				          offset: [, oy],
			          }) => {
				event.preventDefault()

				const currentCameraZoom = defaultCameraZoom
				                          + (
					                          maxCameraZoom
					                          * (
						                          oy
						                          / (
							                          wheelMin
						                          )
					                          )
				                          )
				setCameraZoom(currentCameraZoom)
			},
		},
		{
			target: canvasRef,
			pinch:  {
				scaleBounds: {
					min: pinchMin,
					max: pinchMax,
				},
				rubberband:  true,
			},
			wheel:  {
				bounds:       {
					top:    wheelMin,
					bottom: wheelMax,
				},
				eventOptions: {
					passive: false,
				},
				rubberband:   true,
			},
		},
	)
	//endregion
	//endregion

	return (
		<>
			{/*Leva props*/}
			<Leva
				collapsed={true}
				hidden={!window.debug}
			/>

			<Canvas
				ref={canvasRef}
			>
				{/*Ambient light*/}
				<ambientLight intensity={1}/>

				{/*Camera*/}
				<PerspectiveCamera
					makeDefault
					{...cameraControlsProps}

					zoom={cameraZoom}
				/>

				{/*Camera controls*/}
				{/*<OrbitControls/>*/}
				<PresentationControls
					enabled={true} // the controls can be disabled by setting this to false
					global={true} // Spin globally or by dragging the model
					cursor={false} // Whether to toggle cursor style on drag
					snap={false} // Snap-back to center (can also be a spring config)
					speed={1} // Speed factor
					zoom={1} // Zoom factor when half the polar-max is reached
					{...presentationControlsProps}
					polar={[
						-.3,
						Math.PI
						/ 8,
					]} // Vertical limits
					azimuth={[
						-Infinity,
						Infinity,
					]} // Horizontal limits
					config={{
						mass:     1,
						tension:  170,
						friction: 26,
					}} // Spring config
				>
					{/*Building*/}
					<Building
						clickAction={clickAction}
						numberOfRooms={numberOfRooms}
					/>
				</PresentationControls>
			</Canvas>
		</>
	)
}

export default HabiteoBuilding
